import {FC, useState, createContext} from 'react'
import {useNavigate} from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import storage from 'src/utils/storage'
import user_api from 'src/api/user'
import auth_api from 'src/api/login'

// eslint-disable-next-line @typescript-eslint/no-redeclare
type UserContext = {}
const UsersContext = createContext({} as UserContext)

const UsersProvider: FC = ({children}) => {
  const [user, setUser] = useState<any>()
  const navigation = useNavigate()

  const login = async (values) => {
    if (!values.email || !values.password)
      return {
        msg: 'Missing fields',
        status: false,
      }

    const result = await auth_api.sign_in_email(values)
    if (!result.ok) {
      return {
        msg: 'Invalid credentials',
        status: false,
      }
    }

    let data: any = result.data
    if (data.accountType !== 99)
      return {
        msg: 'You are not allowed to access this portal',
        status: false,
      }

    await storage.store_token(data['token'])
    setUser(data)
    return {
      msg: 'Success Login',
      status: true,
    }
  }

  const check_login = async () => {
    const token = await storage.get_token()
    const current_date = new Date()

    if (!token || jwt_decode(token)['exp'] * 1000 < current_date.getTime()) {
      // await storage.remove()
      // return navigation('/login')
    }

    const result = await user_api.get_user(jwt_decode(token)['id'])
    if (!result.ok) {
      // await storage.remove()
      // return navigation('/login')
    }

    if (result.data['accountType'] !== 99) return navigation('/login')
    setUser(result.data)
  }

  const check_active = () => {
    return user.isActive ? true : false
  }

  const logout = async () => {
    await storage.remove()
    navigation('/login')
  }

  const getUser = async () => {
    const token = await storage.get_token()
    const result = await user_api.get_user(jwt_decode(token)['id'])
    if (!result.ok) {
      await storage.remove()
      return navigation('/login')
    }
    return result.data
  }

  return (
    <UsersContext.Provider value={{user, setUser, getUser, login, check_login, check_active, logout}}>
      {children}
    </UsersContext.Provider>
  )
}

export {UsersContext, UsersProvider}

import {Icon} from '@iconify/react'
import homeFill from '@iconify/icons-eva/home-fill'
import fileFill from '@iconify/icons-eva/file-fill'
import briefcaseFill from '@iconify/icons-eva/briefcase-fill'
import listFill from '@iconify/icons-eva/list-fill'
// routes
import {PATH_DASHBOARD} from 'src/routes/path'

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

const menuConfig = [
  {
    title: 'Dashboard',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.dashboard.app,
  },
  {
    title: 'Users',
    path: PATH_DASHBOARD.dashboard.users,
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  },
  {
    title: 'Gigs',
    path: PATH_DASHBOARD.dashboard.gigs,
    icon: <Icon icon={briefcaseFill} {...ICON_SIZE} />,
  },
  {
    title: 'Logs',
    path: PATH_DASHBOARD.dashboard.logs,
    icon: <Icon icon={listFill} {...ICON_SIZE} />,
  },
  // {
  //   title: 'Ledger',
  //   path: PATH_DASHBOARD.dashboard.ledger,
  //   icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  // },
]

export default menuConfig

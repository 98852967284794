import {Suspense, lazy} from 'react'
import {Navigate, useRoutes} from 'react-router-dom'

import MainLayout from '../views/layouts/MainLayout'
import LogoOnlyLayout from '../views/layouts/LogoOnlyLayout'
import SuspenseLoader from '../components/SuspenseLoader'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// Status
const Status404 = Loader(lazy(() => import('src/views/pages/Status404')))
const Status500 = Loader(lazy(() => import('src/views/pages/Status500')))
const StatusMaintenance = Loader(lazy(() => import('src/views/pages/Maintenance')))

// Pages

const Login = Loader(lazy(() => import('src/views/pages/Login')))

//

const Dashboard = Loader(lazy(() => import('src/views/pages/Dashboard')))

const Users = Loader(lazy(() => import('src/views/pages/Customer')))
const UserProfile = Loader(lazy(() => import('src/views/pages/UserProfile')))

const Gigs = Loader(lazy(() => import('src/views/pages/Gigs')))

const ActivityLogs = Loader(lazy(() => import('src/views/pages/ActivityLogs')))

const Settings = Loader(lazy(() => import('src/views/pages/Settings')))

const AppRoute = () => {
  return useRoutes([
    {
      path: 'dashboard',
      element: <MainLayout />,
      children: [
        {path: '/', element: <Navigate to="/dashboard/app" replace />},
        {path: 'app', element: <Dashboard />},
        {path: 'users', element: <Users />},
        {path: 'gigs', element: <Gigs />},
        {path: 'logs', element: <ActivityLogs />},
        {path: 'settings', element: <Settings />},
      ],
    },
    {
      path: 'users',
      element: <MainLayout />,
      children: [
        {path: '/users', element: <Navigate to="/dashboard/users" replace />},
        {path: 'profile/:id/:type', element: <UserProfile />},
      ],
    },
    {
      path: 'gigs',
      element: <MainLayout />,
      children: [
        {path: '/gigs', element: <Navigate to="/dashboard/gigs" replace />},
        {path: 'profile/:id/:type', element: <UserProfile />},
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {path: '/', element: <Navigate to="/login" replace />},
        {path: 'login', element: <Login />},
        {path: '404', element: <Status404 />},
        {path: '500', element: <Status500 />},
        {path: 'maintenance', element: <StatusMaintenance />},
      ],
    },

    {path: '*', element: <Navigate to="/404" replace />},
  ])
}

export default AppRoute
